import Vue from 'vue';
import store from '../store/index';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
	{
		path: '/',
		name: 'Home',
		component: () => import('../views/Home.vue'),
	},
	{
		path: '/set/shoot',
		name: 'Select State',
		component: () => import('../views/SelectShoot.vue'),
	},
	{
		path: '/settings',
		name: 'Settings',
		component: () => import('../views/Settings/Settings.vue'),
	},
	{
		path: '/settings/scroll',
		name: 'Scroll Settings',
		component: () => import('../views/Settings/ScrollSettings.vue'),
	},
	{
		path: '/settings/announcements',
		name: 'Announcement Settings',
		component: () => import('../views/Settings/AnnouncementSettings.vue'),
	},
];

const router = new VueRouter({
	routes,
});

router.beforeEach((to, from, next) => {
	if (to.query.m) store.commit('MAITAI', to.query.m);

	next();
});

export default router;
