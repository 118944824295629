import Vue from 'vue';
import App from './App.vue';
import router from './router';
import axios from 'axios';
import store from './store';
import EventBus from './ServerEventBus';
import vuetify from './plugins/vuetify';
import MainAPI from '@/assets/js/axios/main_api';
import 'material-design-icons-iconfont/dist/material-design-icons.css';

Vue.prototype.$ServerBus = EventBus;

Vue.prototype.$api = MainAPI;
Vue.prototype.$snackbar = (
	text,
	color = 'warning',
	timeout = 10 * 1000,
	top = false
) => {
	store.commit(
		'snackbar/SHOW_MESSAGE',
		{
			text,
			color,
			timeout,
			top,
		},
		{ root: true }
	);
};
window.$snackbar = Vue.prototype.$snackbar;

if (process.env.NODE_ENV) {
	console.log('In debug  mode, time for requests will be shown');
	axios.interceptors.request.use(
		function(config) {
			config.metadata = { startTime: new Date() };
			config.headers['x-from-service'] = 'rolling-scoreboard';
			if (localStorage.useDev) {
				config.headers['x-next-server'] = 'yes';
				config.headers.common['x-next-server'] = 'yes';
			}
			return config;
		},
		function(error) {
			return Promise.reject(error);
		}
	);

	axios.interceptors.response.use(
		function(response) {
			response.config.metadata.endTime = new Date();
			GetTime(
				response.config.metadata.startTime,
				response.config.metadata.endTime
			);
			return response;
		},
		function(error) {
			error.config.metadata.endTime = new Date();
			error.duration =
				error.config.metadata.endTime - error.config.metadata.startTime;
			GetTime(error.config.metadata.startTime, error.config.metadata.endTime);
			return Promise.reject(error);
		}
	);
}

axios.interceptors.request.use(function(config) {
	config.headers['x-from-service'] = 'rolling-scoreboard';
	if (localStorage.useDev) {
		config.headers['x-next-server'] = 'yes';
		config.headers.common['x-next-server'] = 'yes';
	}
	return config;
});

function GetTime(startTime, endTime) {
	var timeDiff = endTime - startTime; //in ms

	// get seconds
	var miliseconds = Math.abs(Math.round(timeDiff));
	var seconds = Math.abs(Math.round(timeDiff / 1000));
	console.log(`${seconds} Seconds (${miliseconds} Miliseconds)`);
}

Vue.config.productionTip = false;

new Vue({
	router,
	store,
	vuetify,
	render: (h) => h(App),
}).$mount('#app');
