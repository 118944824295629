import Vue from 'vue';
import Vuex from 'vuex';

import shoot from './shoot';
import snackbar from './snackbar';
Vue.use(Vuex);

export default new Vuex.Store({
	state: {
		EventsToScroll: [],
		ScrollTime: 30 * 1000, // 1000,
		EnableScrollDown: true,
		LimitTopScores: 20,
		DisplayClasses: true,
		DisplayCategories: true,
		Announcements: {
			ScrollSpeed: 2, //1-3
			ToggleShowHideInterval: 1 * 60 * 1000, //ONCE A ROLL IS COMPLETE THEN HIDE IT FOR THIS TIME -1 to disable
			Disabled: false,
		},
		ShowHOAHAA: false,
		EventScores: 'Latest Event',
		NowShowing: true,
		ShowWinners: true,
		yardagePunches:[]
	},
	mutations: {
		SETHOAHAA(store, show) {
			store.ShowHOAHAA = show;
			localStorage.HOAHAA = JSON.stringify(show);
		},
		SETDISPLAYCLASSES(store, show) {
			store.DisplayClasses = show;
			localStorage.DISPLAYCLASSES = JSON.stringify(show);
		},
		SETDISPLAYCATEGORIES(store, show) {
			store.DisplayCategories = show;
			localStorage.DISPLAYCATEGORIES = JSON.stringify(show);
		},
		SETSCROLLSPEED(store, v) {
			store.ScrollTime = v;
			localStorage.SCROLLTIME = JSON.stringify(v);
		},
		SETSCROLLDOWN(store, v) {
			store.EnableScrollDown = v;
			localStorage.ENABLESCROLLDOWN = JSON.stringify(v);
		},
		SETLIMIT(store, v) {
			store.LimitTopScores = v;
			localStorage.LIMITTOPSCORES = JSON.stringify(v);
		},
		SETANNOUNCEMENTS(store, v) {
			store.Announcements = v;
			localStorage.Announcements = JSON.stringify(v);
		},
		SETEVENTSCORES(store, v) {
			store.EventScores = v;
			localStorage.EventScores = JSON.stringify(v);
		},
		SETNOWSHOWING(store, v) {
			store.NowShowing = v;
			localStorage.NowShowing = JSON.stringify(v);
		},
		SETSHOWWINNERS(store, v) {
			store.ShowWinners = v;
			localStorage.ShowWinners = JSON.stringify(v);
		},
		MAITAI(store, v) {
			store.maitai = v;
			localStorage.maitai = v;
		},
		SETYARDAGEPUNCHES(store, v) {
			store.yardagePunches = v;
		},
	},
	actions: {
		loadFromLocalStorage: (context) => {
			if (localStorage.EventScores)
				context.commit('SETEVENTSCORES', JSON.parse(localStorage.EventScores));
			if (localStorage.HOAHAA)
				context.commit('SETHOAHAA', JSON.parse(localStorage.HOAHAA));
			if (localStorage.DISPLAYCLASSES)
				context.commit(
					'SETDISPLAYCLASSES',
					JSON.parse(localStorage.DISPLAYCLASSES)
				);
			if (localStorage.DISPLAYCLASSES)
				context.commit(
					'SETDISPLAYCLASSES',
					JSON.parse(localStorage.DISPLAYCLASSES)
				);
			if (localStorage.DISPLAYCATEGORIES)
				context.commit(
					'SETDISPLAYCATEGORIES',
					JSON.parse(localStorage.DISPLAYCATEGORIES)
				);
			if (localStorage.SCROLLTIME)
				context.commit('SETSCROLLSPEED', JSON.parse(localStorage.SCROLLTIME));
			if (localStorage.ENABLESCROLLDOWN)
				context.commit(
					'SETSCROLLDOWN',
					JSON.parse(localStorage.ENABLESCROLLDOWN)
				);
			if (localStorage.LIMITTOPSCORES)
				context.commit('SETLIMIT', JSON.parse(localStorage.LIMITTOPSCORES));
			if (localStorage.Announcements)
				context.commit(
					'SETANNOUNCEMENTS',
					JSON.parse(localStorage.Announcements)
				);
			if (localStorage.NowShowing)
				context.commit('SETNOWSHOWING', JSON.parse(localStorage.NowShowing));

			if (localStorage.ShowWinners)
				context.commit('SETSHOWWINNERS', JSON.parse(localStorage.ShowWinners));
		},
		set_hoahaa: (context, hoa) => {
			context.commit('SETHOAHAA', hoa);
		},
		set_displayclasses: (context, yesno) => {
			context.commit('SETDISPLAYCLASSES', yesno);
		},
		set_categories: (context, yesno) => {
			context.commit('SETDISPLAYCATEGORIES', yesno);
		},
		set_scrollspeed: (context, v) => {
			context.commit('SETSCROLLSPEED', v);
		},
		set_scrolldown: (context, yesno) => {
			context.commit('SETSCROLLDOWN', yesno);
		},
		set_limit: (context, val) => {
			context.commit('SETLIMIT', val);
		},
		set_announcement: (context, v) => {
			context.commit('SETANNOUNCEMENTS', v);
		},
		set_eventscores: (context, v) => {
			context.commit('SETEVENTSCORES', v);
		},
		set_nowshowing: (context, v) => {
			context.commit('SETNOWSHOWING', v);
		},
		set_showwinner: (context, v) => {
			context.commit('SETSHOWWINNERS', v);
		},
	},
	modules: {
		shoot,
		snackbar,
	},
});
