<template>
	<div>
		<v-snackbar
			v-model="show"
			:color="color"
			:timeout="timeout"
			:top="top"
			min-height="30px"
			min-width="80vw"
		>
			<div class="text-h4 text-center text-no-wrap">
				{{ text }}
			</div>
		</v-snackbar>
	</div>
</template>

<script>
export default {
	data() {
		return {
			show: false,
			color: '',
			text: '',
			top: false,
			timeout: 1000,
		};
	},
	created() {
		this.$store.subscribe((mutation, state) => {
			if (mutation.type === 'snackbar/SHOW_MESSAGE') {
				this.text = state.snackbar.text;
				this.color = state.snackbar.color;
				this.timeout = state.snackbar.timeout;
				this.top = state.snackbar.top;
				this.show = true;
			}
		});
	},
};
</script>
