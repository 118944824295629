'use strict';

import io from 'socket.io-client';
import store from './store/index';

window.bus = {
	status: 'not initialized',
	heartbeats: 0,
	events: 0,
	errors: 0,
	lastHeartBeat: new Date(),
	reconnections: 0,
	lastRoom: null,
};

const socket = io('https://eventbus.shootpro.cloud');
// const socket = io('http://localhost:10');
let ShootID, ClubID;
let unexpectedDisconnect = false;

store.subscribe((details, state) => {
	details;
	if (state.shoot.ShootID && state.shoot.ClubID) {
		if (ShootID !== state.shoot.ShootID || ClubID !== state.shoot.ClubID) {
			ShootID = state.shoot.ShootID;
			ClubID = state.shoot.ClubID;
			const isProd = !localStorage.useDev;
			let appendName = '';
			if (!isProd) appendName = '-dev';
			const roomName = `${ClubID}-${ShootID}${appendName}`;
			window.roomName = roomName;
			window.bus.lastRoom = roomName;
			window.roomName = roomName;
			console.info(`Joining: ${roomName}`);
			socket.emit('join', roomName);
		}
	}
});

socket.on('connect', () => {
	if (unexpectedDisconnect) {
		window.bus.reconnections++;
		if (window.bus.lastRoom && window.bus.lastRoom.includes('-')) {
			console.warn('Force rejoining room', window.bus.lastRoom);
			socket.emit('join', window.bus.lastRoom);
		}
	}

	//IF IN DEVELOPMENT MODE LOG THAT THE EVENTBUS WAS CONNECTED
	if (process.env.NODE_ENV === 'development') {
		console.log('Eventbus connected!');
	}
	window.bus.status = 'connected';

	socket.on('❤️', () => {
		window.bus.heartbeats++;
		window.bus.lastHeartBeat = new Date();
	});

	socket.on('invalid-event', (data) => {
		console.warn('Invalid-event on event bus', data);
	});

	socket.on('who-is-active', () => {
		socket.emit('emit-all', {
			type: 'i-am-active',
			data: {
				room: window.roomName || 'n/a',
				club: store.state.shoot.ClubID || 'n/a',
				clubName: store.state.shoot.Club.ClubName || 'n/a',
				shoot: store.state.shoot.ShootID || 'n/a',
				shootName: 'n/a',
				userId: 'Anonymous',
				userRole: 'Anonymous',
				currentRoute: window.location.pathname,
				currentHost: window.location.host,
				href: window.location.href,
				userAgent: window.navigator.userAgent,
			},
		});
	});

	socket.on('welcome-to-room', (data) => {
		console.info('Successfully joined room', data);
	});

	socket.on('close-down-window', () => {
		window.location.href = 'https://shootpro.cloud';
	});

	socket.on('error', () => {
		console.warn('Eventbus disconnecting due to error');
		window.bus.status = 'disconnected - error';
		window.bus.errors++;
	});

	socket.on('reconnect', (attempt) => {
		console.warn(`Eventbus reconnecting attempt #${attempt}`);
		window.bus.status = 'reconnecting';
	});

	socket.on('reconnect_error', (error) => {
		console.error('Eventbus cannot reconnect', error);
		window.bus.status = 'reconnection - failed';
		window.bus.errors++;
	});
	socket.prependAny((x, ...y) => {
		window.bus.events++;
		window.bus.lastEvent = x;
		window.bus.lastEventData = JSON.stringify(y);
		logBus('in', x, y);
	});
	socket.prependAnyOutgoing((x, ...y) => {
		logBus('out', x, y);
	});
	socket.on('connect_error', (err) => {
		console.log(`connect_error due to ${err.message}`);
		logBus('out', 'connect_error', err);
	});
});

async function logBus(direction, message, params) {
	let busLog = [];
	if (localStorage.busLog) {
		busLog = JSON.parse(localStorage.busLog);
	}
	busLog.unshift({
		direction,
		message,
		params,
		ts: Date.now(),
	});
	if (busLog.length > 1000) {
		busLog.pop();
	}
	localStorage.busLog = JSON.stringify(busLog);
}

socket.on('disconnect', () => {
	unexpectedDisconnect = true;
	console.warn('Event bus disconnected');
});
window.__ss_web_socket = socket;
export default socket;
